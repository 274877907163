import { uploadFiles, downloadFiles } from "@/modules/storage/services/FileService.js";
import { createFolder, downloadFolders } from "@/modules/storage/services/FolderService.js";
import { convertToFormData } from "@/helpers/file.js";
import store from "@/store";

const getUserFromState = () => {
  store.state.auth.user;
  const stateUser = store.state.auth.user;
  stateUser.full_name = `${stateUser.first_name} ${stateUser.last_name}`;
  return stateUser;
};

export default {
  uploadFolder(context, folder) {
    const user = getUserFromState();
    const formData = convertToFormData(folder.files);
    formData.append("user[id]", user.id);
    formData.append("user[name]", user.full_name);
    formData.append("name", folder.name);
    formData.append("color", "#5f6368");

    if (folder.folderId) formData.append("folder_id", folder.folderId);

    return createFolder(user.account_id, formData)
      .then((resp) => {
        context.commit("setUploaded", { type: "folder", data: resp.data });
        return resp;
      })
      .finally(() => context.commit("consuming"));
  },
  uploadFiles(context, data) {
    const formData = convertToFormData(data.files);
    const user = getUserFromState();
    formData.append("user[id]", user.id);
    formData.append("user[name]", user.full_name);
    if (data.folderId) formData.append("folder_id", data.folderId);

    return uploadFiles(user.account_id, formData)
      .then((resp) => {
        context.commit("setUploaded", { type: "files", data: resp.data.uploaded });
        return resp;
      })
      .finally(() => context.commit("consuming"));
  },

  downloadFolder(context, { accountId, ids }) {
    return downloadFolders(accountId, ids).finally(() => context.commit("consumingDownload"));
  },

  downloadFiles(context, { accountId, ids }) {
    return downloadFiles(accountId, ids).finally(() => context.commit("consumingDownload"));
  },
};
