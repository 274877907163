/* eslint no-param-reassign: ["error", { "props": false }] */

import Vue from "vue";
import Router from "vue-router";
import config from "./config/router";
import moduleRoutes from "./plugins/modules";

Vue.use(Router);
const router = new Router({
  ...config,
  moduleRoutes,
  routes: [
    {
      path: "*",
      name: "404",
      component: () => import("@/modules/config/views/NotFound404.vue"),
    },
  ],
});

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;
  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  const middleware = [];
  // Default middleware
  middleware.push(...config.middleware);

  if (to.meta.middleware) {
    middleware.push(...to.meta.middleware);
  }

  if (!_.isEmpty(middleware)) {
    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);
    return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next();
});

/**
 * Export Router instance
 */
export default router;
