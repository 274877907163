export default {
  async getTypeByRoute(context, type = "feed") {
    if (Array.isArray(type)) {
      const plural = type.length > 1 ? "dos tipos" : "do tipo";
      return {
        description: `Pesquisa ${plural}: ${type.join(",")}`,
        icon: "mdi-magnify",
        types: type,
        route: "*",
      };
    }
    return context.getters.mediaTypes[type];
  },

  async getTypeByText(context, type) {
    return Object.values(context.getters.mediaTypes).find((mediaType) =>
      mediaType.types.includes(type),
    );
  },
};
