import AuthMiddleware from "@/middleware/auth";

export default [
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("../views/Dashboard.vue"),
    meta: {
      middleware: [AuthMiddleware],
      title: "Dashboard",
    },
  },
  {
    path: "/dashboard/mk",
    name: "dash-mk",
    component: () => import("../views/DashMK.vue"),
    meta: {
      title: "Mary Kay",
    },
  },
  {
    path: "/dashboard/dasa",
    name: "dash-dasa",
    component: () => import("../views/DashboardDasa.vue"),
    meta: {
      title: "Dasa",
    },
  },
  {
    path: "/dashboard/claro/pj",
    name: "dash-claro-pj",
    component: () => import("../views/DashboardClaro.vue"),
    meta: {
      middleware: [AuthMiddleware],
      title: "Claro",
      guards: ["acl", "feature"],
      slug: "mapping.dashboard.claro.view",
      featureSlug: "mapping.dashboard.claro.view",
    },
  },
  {
    path: "/dashboard/claro/pf",
    name: "dash-claro-pf",
    component: () => import("../views/DashboardClaroPF.vue"),
    meta: {
      middleware: [AuthMiddleware],
      title: "Claro",
      guards: ["acl", "feature"],
      slug: "mapping.dashboard.claro.view",
      featureSlug: "mapping.dashboard.claro.view",
    },
  },
  {
    path: "/dashboard/product",
    name: "dash-product",
    component: () => import("../views/DashboardProduct.vue"),
    meta: {
      middleware: [AuthMiddleware],
      title: "Dashboard",
      guards: ["acl", "feature"],
      slug: "product.dashboard",
      featureSlug: "product.dashboard",
    },
  },
  {
    path: "/dashboard/clipping/default",
    name: "dash-clipping-default",
    component: () => import("../views/DashboardClippingDefault.vue"),
    meta: {
      middleware: [AuthMiddleware],
      title: "Dashboard",
      guards: ["acl", "feature"],
      slug: "clipping.dashboard.default",
      featureSlug: "clipping.dashboard.default",
    },
  },
];
