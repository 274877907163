import acl from "@/plugins/acl";

export default {
  navigationDrawer: (state) => state.navigationDrawer,
  homePage: (state) => {
    const initialRoute = state.navigationDrawer.items.find(
      (item) => item.is_initial === true && (item.acl ? acl.can(item.acl) : true),
    );
    return initialRoute ? initialRoute.route : { name: "feed" };
  },
};
