export default [
  {
    path: "/config/users",
    name: "users",
    component: () => import(/* webpackChunkName: "users" */ "../views/Users.vue"),
    meta: {
      title: "Users",
      layout: "config",
      guards: ["acl", "feature"],
      slug: "users.view",
      featureSlug: "users",
    },
  },
  {
    path: "/config/scopes",
    name: "scopes",
    component: () => import(/* webpackChunkName: "scopes" */ "../views/Categories.vue"),
    meta: {
      title: "Scopes",
      layout: "config",
      guards: ["acl", "feature"],
      slug: "scope.view",
      featureSlug: "acl",
    },
  },
  {
    path: "/config/scopes/:id",
    name: "scopes.form",
    props: true,
    component: () => import(/* webpackChunkName: "scopes" */ "../views/CategoryForm.vue"),
    meta: {
      title: "Scopes",
      layout: "config",
      guards: ["acl", "feature"],
      slug: "scope.update",
      featureSlug: "acl",
    },
  },
  {
    path: "/config/roles",
    name: "roles",
    component: () => import(/* webpackChunkName: "roles" */ "../views/Roles.vue"),
    meta: {
      title: "Grupos",
      layout: "config",
      guards: ["acl", "feature"],
      slug: "role.view",
      featureSlug: "acl",
    },
  },
  {
    path: "/config/roles/:id",
    name: "role.form",
    props: true,
    component: () => import(/* webpackChunkName: "roles" */ "../views/RoleForm.vue"),
    meta: {
      title: "Grupo - Formulário",
      layout: "config",
      guards: ["acl", "feature"],
      slug: "role.update",
      featureSlug: "acl",
    },
  },
];
