export const getNestedPath = (object, path) => {
  return path.split(".").reduce((itemAccumulator, field) => {
    if (itemAccumulator[field] === undefined) {
      return null;
    }

    return itemAccumulator[field];
  }, object);
};

export const convertToFormData = (data) => {
  const formData = new FormData();

  const addProperties = (object, prefix = "") => {
    for (const [key, value] of Object.entries(object)) {
      const name = prefix ? `${prefix}[${key}]` : key;
      if (value === undefined) continue;
      if (value === null) continue;
      if (value instanceof File) {
        formData.append(name, value, value.name);
      } else if (typeof value === "object" && value !== null) {
        addProperties(value, name);
      } else if (Array.isArray(value)) {
        value.forEach((item, index) => addProperties(item, `${name}[${index}]`));
      } else {
        formData.append(name, value);
      }
    }
  };

  if (Array.isArray(data)) {
    data.forEach((item, index) => addProperties(item, `[${index}]`));
  } else {
    addProperties(data);
  }

  return formData;
};

export const tryParse = (value, type) => {
  let parsedValue = value;

  try {
    parsedValue = JSON.parse(value);
  } catch (err) {
    console.error(err);
  }

  if (type === "array") {
    if (Array.isArray(parsedValue)) return parsedValue;

    const typeOfParsedValue = typeof parsedValue;

    if (typeOfParsedValue === "string") return [parsedValue];

    return [];
  }

  if (type === "object") {
    if (Array.isArray(parsedValue)) return {};

    const typeOfParsedValue = typeof parsedValue;

    if (typeOfParsedValue === "object") return parsedValue;

    return {};
  }

  if (type === "string") {
    if (typeof parsedValue === "undefined" || parsedValue === "") return null;

    return parsedValue;
  }

  return parsedValue;
};

export const parseObjectMapper = (query, mapping = {}) => {
  const mappings = Object.keys(mapping);

  return mappings.reduce((acc, key) => {
    const value = tryParse(query[key], mapping[key]);
    acc[key] = value;
    return acc;
  }, {});
};

export default {
  getNestedPath,
  convertToFormData,
};
