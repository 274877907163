export const getFileNameFromHeaders = (headers) => {
  if (headers["filename"]) return headers["filename"];
  return headers["content-disposition"].split("filename=")[1].split(";")[0].replaceAll('"', "");
};
export const downloadFromResponse = (response, filename = null) => {
  const { data, headers, config } = response;
  if (!config.responseType || config.responseType !== "blob") {
    console.error('a request deve conter a config "responseType: `blob`,"');
    return;
  }
  const blob = new Blob([data], { type: headers["content-type"] });
  const a = document.createElement("a");
  a.href = URL.createObjectURL(blob);
  a.download = headers["content-disposition"] ? getFileNameFromHeaders(headers) : filename;
  a.click();
};

export const downloadFromUrl = (url, fileName = true) => {
  const a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  a.click();
};
export const convertToFormData = (fileList) => {
  const formData = new FormData();
  Object.keys(fileList).forEach((key) => {
    formData.append(`files[]`, fileList[key]);
  });
  return formData;
};

export const sumFileListSize = (arrFileList) => {
  // return mb unit
  const sum = arrFileList.reduce((acc, file) => {
    acc += file.size;
    return acc;
  }, 0);
  return (sum / (1024 * 1024)).toFixed(2);
};

function createFolderHierachy(foldersName, file, target) {
  const folderName = foldersName.splice(0, 1);

  if (target[folderName] === undefined) {
    target[folderName] = {
      files: [],
      childs: {},
    };
  }

  if (foldersName.length === 0) {
    target[folderName].files.push(file);
    return;
  }

  createFolderHierachy(foldersName, file, target[folderName].childs);
}

export const transformFolderFilesToObject = (fileList, filterMimes) => {
  const result = {};
  Object.values(fileList).forEach((file) => {
    const filterMimePass = filterMimes.length
      ? filterMimes.some((m) => file.type.includes(m))
      : true;
    if (!filterMimePass) return;

    const paths = file.webkitRelativePath.split("/");
    const subFoldersName = paths.slice(0, paths.length - 1);
    createFolderHierachy(subFoldersName, file, result);
  });
  return result;
};

export const getPreview = (file) => {
  return new Promise((res, rej) => {
    try {
      const fr = new FileReader();
      fr.readAsDataURL(file);
      fr.onload = () => res(fr.result);
    } catch (err) {
      rej(err);
    }
  });
};
