import router from "@/router";

export default function ({ next }) {
  if (!localStorage.getItem("accessToken") || !localStorage.getItem("user")) {
    // Redirect to login page
    return router.push({ name: "login" });
  }

  return next();
}
