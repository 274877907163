import { getNestedPath } from "@/helpers/object";

import store from "@/modules/auth/store/index";

class Config {
  constructor() {
    this.Vue = null;

    this.options = {
      vmProperty: "$config",
    };
  }

  install(Vue) {
    this.Vue = Vue;

    this.registerVmProperty();
  }

  registerVmProperty() {
    const { vmProperty } = this.options;

    this.Vue.prototype[vmProperty] = this.config;
  }

  config(slug, config) {
    if (!store.state.user || !store.state.user.account || !store.state.user.account.metas)
      return true;

    const configs = [...store.state.user.account.metas];
    const availableConfig = configs.find((c) => c.slug === slug);

    if (!availableConfig) return true;

    const value = getNestedPath(availableConfig.value, config);
    return value == null ? true : value;
  }
}

export default new Config();
