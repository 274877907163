export default [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login.vue"),
    meta: {
      title: "Minha Conta",
      layout: "auth",
    },
  },
  {
    path: "//login",
    name: "login-errado",
    component: () => import("../views/Login.vue"),
    meta: {
      title: "Minha Conta",
      layout: "auth",
    },
  },
  {
    path: "/password-reset",
    name: "password-reset",
    component: () => import("../views/PasswordReset.vue"),
    meta: {
      title: "Esqueci minha Senha",
      layout: "auth",
    },
  },
  {
    path: "/suspicious-login",
    name: "suspicious.login",
    component: () => import("../views/SuspiciousLogin.vue"),
    meta: {
      title: "Login suspeito",
      layout: "auth",
    },
  },
  {
    path: "/sso-callback",
    name: "sso.callback",
    component: () => import("../views/SSOCallback.vue"),
    meta: {
      title: "Callback",
      layout: "blank",
    },
  },
];
