import Http from "@/plugins/http";

const prefix = "drawer";

export const getAccountDrawer = (account_id) => Http.get(`${prefix}`, { params: { account_id } });

export const getDrawersPaginate = (args = {}) => Http.get(`${prefix}/paginate`, { params: args });

export const getDrawerById = (id) => Http.get(`${prefix}/${id}`);

export const createDrawer = (data) => Http.post(`${prefix}`, data);

export const updateDrawer = (id, data) => Http.put(`${prefix}/${id}`, data);

export const deleteDrawer = (id) => Http.delete(`${prefix}/${id}`);

export const getAllDrawerItems = () => Http.get(`${prefix}-items`);

export const copyDrawerFromAccount = (fromAccountId, toAccountId) =>
  Http.post(`${prefix}/copy`, { fromAccountId, toAccountId });

export const getDrawerItemsPaginate = (args = {}) =>
  Http.get(`${prefix}-items/paginate`, { params: args });

export const createDrawerItem = (data) => Http.post(`${prefix}-items`, data);

export const updateDrawerItem = (id, data) => Http.put(`${prefix}-items/${id}`, data);

export const deleteDrawerItem = (id) => Http.delete(`${prefix}-items/${id}`);
