import Drag from "./drag";
import Drop from "./drop";

class DragAndDrop {
  install(Vue) {
    this.createDirectiveDrag(Vue);
    this.createDirectiveDrop(Vue);
  }

  createDirectiveDrag(Vue) {
    Vue.directive("drag", {
      bind(el, binding) {
        el.drag = new Drag(el, {
          id: binding.arg ? binding.arg : false,
          value: binding.value,
        });
        el.drag.start();
      },
      update(el, binding) {
        el.drag.setValue(binding.value);
      },
    });
  }

  createDirectiveDrop(Vue) {
    Vue.directive("drop", {
      bind(el, binding) {
        el.drop = new Drop(el, {
          id: binding.arg ? binding.arg : false,
          action: binding.value,
        });
        el.drop.start();
      },
    });
  }
}

export default new DragAndDrop();
