import AuthMiddleware from "@/middleware/auth";

export default [
  {
    path: "/contacts",
    name: "contacts",
    component: () => import(/* webpackChunkName: "contacts" */ "../views/ContactList.vue"),
    meta: {
      middleware: [AuthMiddleware],
      title: "Contatos",
      guards: ["acl"],
      slug: "clipping.contacts.view",
    },
  },
];
