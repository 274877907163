import AuthMiddleware from "@/middleware/auth";

export default [
  {
    path: "/search",
    name: "search",
    component: () => import(/* webpackChunkName: "feed" */ "../views/Search.vue"),
    meta: {
      middleware: [AuthMiddleware],
      title: "Resultado de Busca",
    },
  },
  {
    path: "/search-filter",
    name: "search-filter",
    component: () => import(/* webpackChunkName: "feed" */ "../views/Search.vue"),
    meta: {
      middleware: [AuthMiddleware],
    },
  },
  {
    path: "/search/media",
    name: "search.media",
    component: () => import(/* webpackChunkName: "feed" */ "../views/FreeSearch.vue"),
    meta: {
      middleware: [AuthMiddleware],
      title: "Pesquisa",
      showSearchBar: false,
      containerStyle: {
        height: "100%",
      },
    },
  },
  {
    path: "/search/media/result",
    name: "search.media.result",
    component: () => import(/* webpackChunkName: "feed" */ "../views/SearchResult.vue"),
    meta: {
      middleware: [AuthMiddleware],
      title: "Resultado de Busca",
      showSearchBar: false,
      containerStyle: {
        height: "100%",
      },
    },
  },
];
