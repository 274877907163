export default {
  getQueue: (state) => state.queue,
  isUploading: (state) => state.uploading,
  getConsuming: (state) => state.consuming,
  getUploaded: (state) => state.uploaded,
  //  download
  isDownloading: (state) => state.downloading,
  getDownloadQueue: (state) => state.downloadQueue,
  getDownloadConsuming: (state) => state.downloadConsuming,
};
