/* import AuthMiddleware from "@/middleware/auth"; */

export default [
  {
    path: "/btl",
    name: "btl.view",
    component: () => import(/* webpackChunkName: "feed" */ "../views/Btl.vue"),
    meta: {
      title: "BTL",
      guards: [],
      slug: "btl.view",
      containerStyle: { height: "100%" },
    },
  },
];
