import AuthMiddleware from "@/middleware/auth";

export default [
  {
    path: "/mappings",
    name: "mappings",
    component: () => import("../views/Mappings.vue"),
    meta: {
      middleware: [AuthMiddleware],
      title: "Mapeamentos",
      guards: ["acl"],
      slug: "mapping.view",
    },
  },
  {
    path: "/mappings/:collection/details",
    name: "mappings.details",
    component: () => import("../views/Player.vue"),
    props: true,
    meta: {
      middleware: [AuthMiddleware],
      title: "Player",
      guards: ["acl"],
      slug: "mapping.view",
    },
  },
];
