import AuthMiddleware from "@/middleware/auth";

export default [
  {
    path: "/groups",
    name: "groups",
    component: () => import(/* webpackChunkName: "groups" */ "../views/Groups.vue"),
    meta: {
      middleware: [AuthMiddleware],
      title: "Grupos",
      guards: ["acl"],
      slug: "clipping.groups.view",
    },
  },
  {
    path: "/groups/create",
    name: "group.create",
    component: () => import(/* webpackChunkName: "groups" */ "../views/Group.vue"),
    meta: {
      middleware: [AuthMiddleware],
      title: "Novo Grupo",
      guards: ["acl"],
      slug: "clipping.groups.create",
    },
  },
  {
    path: "/groups/edit/:id",
    name: "group.edit",
    component: () => import(/* webpackChunkName: "groups" */ "../views/Group.vue"),
    meta: {
      middleware: [AuthMiddleware],
      title: "Editar Grupo",
      guards: ["acl"],
      slug: "clipping.groups.update",
    },
  },
];
