const Authorization = {
  /**
   * Installer Authorization plugin
   * @param Vue
   * @param options
   */
  install(Vue) {
    /**
     * Authorization functions
     * @type {{title: (function(*))}}
     */
    Vue.prototype.$authorization = {
      /**
       * Verify Authorization
       * @param scope
       * @returns {boolean}
       */
      can() {
        if (localStorage.getItem("user")) {
          const user = JSON.parse(localStorage.getItem("user"));

          if (user.account.name === "Yoki") {
            return false;
          }

          if (user && user.email) {
            const emailsDenied = [
              "emanoel.oliveira@itau-unibanco.com.br",
              "mariana-cassia.costa@itau-unibanco.com.br",
              "claudia.furniel@itau-unibanco.com.br",
              "taiana.loroza@oi.net.br",
              "bradesco1@uotz.com.br",
              "bradesco2@uotz.com.br",
              "bradesco3@uotz.com.br",
              "bradesco4@uotz.com.br",
              "bradesco5@uotz.com.br",
              "bradesco6@uotz.com.br",
              "nathan.adelino@dpzt.com.br",
              "patricia.alves@dpzt.com.br",
              "marcos.yamamura@dpzt.com.br",
              "marcelo.azevedo@dpzt.com.br",
              "fabio.montenegro@dpzt.com.br",
              "luana.paolillo@dpzt.com.br",
              "arthur.camara@dpzt.com.br",
              "wagner.oliveira@dpzt.com.br",
              "gabriela.brigagao@dpzt.com.br",
              "patricia.menezes@dpzt.com.br",
              "gabriela.canal@dpzt.com.br",
              "vinicius.frudeli@dpzt.com.br",
              "alessandra.gregio@itau-unibanco.com.br",
              "barbara.rosa-santos@itau-unibanco.com.br",
              "claudia.furniel@itau-unibanco.com.br",
              "gabriela.mendonca@itau-unibanco.com.br",
              "hikari.fukashiro@itau-unibanco.com.br",
              "livia.minhoto@itau-unibanco.com.br",
              "luana-duarte.siqueira@itau-unibanco.com.br",
              "talita.lacerda@itau-unibanco.com.br",
              "thyago.simoes@itau-unibanco.com.br",
              "tomas.monje@itau-unibanco.com.br",
            ];

            return !emailsDenied.includes(user.email) && user.email.includes("@uotz");
          }
        }

        return false;
      },

      hostCan(hosts) {
        if (localStorage.getItem("user")) {
          const user = JSON.parse(localStorage.getItem("user"));

          if (user && user.email) {
            for (let index = 0; index < hosts.length; index += 1) {
              if (user.email.includes(hosts[index])) {
                return true;
              }
            }
          }
        }

        return false;
      },

      usersCan(emails) {
        if (localStorage.getItem("user")) {
          const user = JSON.parse(localStorage.getItem("user"));

          if (user && user.email) {
            return emails.includes(user.email);
          }
        }

        return false;
      },

      usersCanNot(emails) {
        if (localStorage.getItem("user")) {
          const user = JSON.parse(localStorage.getItem("user"));

          if (user && user.email) {
            return !emails.includes(user.email);
          }
        }

        return false;
      },
    };
  },
};

export default Authorization;
