import AuthMiddleware from "@/middleware/auth";

export default [
  {
    path: "/news-monitoring/create",
    name: "newsmonitoring.create",
    component: () =>
      import(/* webpackChunkName: "monitoramento" */ "../views/createNewsMonitoring.vue"),
    meta: {
      middleware: [AuthMiddleware],
      title: "Novo Monitoramento",
      layout: "blank",
      guards: ["acl"],
      slug: "newsmonitoring.create",
    },
  },
];
