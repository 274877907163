import TranslatePt from "vuetify/lib/locale/pt";

export default {
  icons: {
    iconfont: "mdi",
  },
  lang: {
    locales: { TranslatePt },
    current: "TranslatePt",
  },
  theme: {
    themes: {
      light: {
        primary: "#132D42",
        secondary: "#ED5F00",
        background: "#E7EFF6",
        error: "#dc3545",
      },
    },
  },
};
