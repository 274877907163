import AuthMiddleware from "@/middleware/auth";

export default [
  {
    path: "/config/classification",
    name: "config.classification",
    component: () => import(/* webpackChunkName: "feed" */ "../views/Classification.vue"),
    meta: {
      middleware: [AuthMiddleware],
      title: "Configurações",
      layout: "config",
      guards: ["acl", "feature"],
      slug: "config.view",
      featureSlug: "classifications",
    },
  },
  {
    path: "/config/filter",
    name: "config.filter",
    component: () => import(/* webpackChunkName: "feed" */ "../views/Filters.vue"),
    meta: {
      middleware: [AuthMiddleware],
      title: "Filtros",
      layout: "config",
      guards: ["acl", "feature"],
      slug: "config.view",
      featureSlug: "filters",
    },
  },
  {
    path: "/config/filter/:id/item",
    name: "config.filter.item",
    component: () => import(/* webpackChunkName: "feed" */ "../views/Filter.vue"),
    meta: {
      middleware: [AuthMiddleware],
      title: "Item Filtro",
      layout: "config",
      guards: ["acl", "feature"],
      slug: "config.view",
      featureSlug: "filters",
    },
  },

  {
    path: "/config/accounts",
    name: "config.accounts",
    component: () => import(/* webpackChunkName: "feed" */ "@/modules/config/views/Accounts.vue"),
    meta: {
      middleware: [AuthMiddleware],
      title: "Contas",
      layout: "config",
      guards: ["acl", "feature"],
      slug: "account.view",
      featureSlug: "accounts",
    },
  },

  {
    path: "/config/media-report",
    name: "media.report",
    component: () =>
      import(/* webpackChunkName: "feed" */ "@/modules/config/views/MediaReport.vue"),
    meta: {
      middleware: [AuthMiddleware],
      title: "Relatório de Midias",
      layout: "config",
      guards: ["acl", "feature"],
      slug: "media.report",
      featureSlug: "media-report",
    },
  },
  {
    path: "/config/account-features/:accountId",
    name: "account.features.view",
    component: () => import("@/modules/config/views/AccountFeatures.vue"),
    props: true,
    meta: {
      middleware: [AuthMiddleware],
      title: "Funcionalidades",
      layout: "config",
      guards: ["acl", "feature"],
      slug: "features.view",
      featureSlug: "functionalities",
    },
  },
  {
    path: "/config/features",
    name: "features.view",
    component: () => import("@/modules/config/views/Features.vue"),
    meta: {
      middleware: [AuthMiddleware],
      title: "Funcionalidades",
      layout: "config",
      guards: ["acl", "feature"],
      slug: "features.view",
      featureSlug: "functionalities",
    },
  },
  {
    path: "/config/features/:categoryId",
    name: "features.manage",
    component: () => import("@/modules/config/views/FeaturesManagement.vue"),
    props: true,
    meta: {
      middleware: [AuthMiddleware],
      title: "Funcionalidades",
      layout: "config",
      guards: ["acl", "feature"],
      slug: "features.view",
      featureSlug: "functionalities",
    },
  },
  {
    path: "/config/drawers",
    name: "drawers.view",
    component: () => import("@/modules/config/views/Drawers.vue"),
    props: true,
    meta: {
      middleware: [AuthMiddleware],
      title: "Drawers",
      layout: "config",
      guards: ["acl", "feature"],
      slug: "drawer.view",
      featureSlug: "drawers",
    },
  },
  {
    path: "/config/drawers-items",
    name: "drawers.items.view",
    component: () => import("@/modules/config/views/DrawerItems.vue"),
    props: true,
    meta: {
      middleware: [AuthMiddleware],
      title: "Drawers Items",
      layout: "config",
      guards: ["acl", "feature"],
      slug: "drawer.view",
      featureSlug: "drawers",
    },
  },
  {
    path: "/config/drawer/:id",
    name: "drawers.create.or.update",
    component: () => import("@/modules/config/views/DrawerCreateOrUpdate.vue"),
    props: true,
    meta: {
      middleware: [AuthMiddleware],
      title: "Drawers Items",
      layout: "config",
      guards: ["acl", "feature"],
      slug: "drawer.view",
      featureSlug: "drawers",
    },
  },
  {
    path: "/config",
    name: "config.adminarea",
    component: () => import(/* webpackChunkName: "feed" */ "../views/AdminArea.vue"),
    meta: {
      middleware: [AuthMiddleware],
      title: "Área Administrativa",
      layout: "config",
      slug: "config.view",
      featureSlug: "classifications",
    },
  },
];
