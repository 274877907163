import AuthMiddleware from "@/middleware/auth";

export default [
  {
    path: "/home",
    name: "home",
    component: () => import("../views/Home.vue"),
    meta: {
      middleware: [AuthMiddleware],
      title: "Home",
    },
  },
];
