import { getAccountDrawer } from "@/modules/config/services/DrawerService.js";
import store from "@/store";

export default {
  async setDrawer(context) {
    await getAccountDrawer(store.state.auth.user.account_id)
      .then((response) => {
        context.commit("setNavigationDrawer", response.data);
      })
      .catch(() => {});
  },
};
