import AuthMiddleware from "@/middleware/auth";

export default [
  {
    path: "/media/no-classified",
    name: "media.no-classified",
    component: () => import(/* webpackChunkName: "media" */ "../views/MediaList.vue"),
    meta: {
      middleware: [AuthMiddleware],
      title: "Listagem de Mídias Não Classificadas",
      guards: ["acl"],
      slug: "media.notclassified.view",
    },
  },

  {
    path: "/media/report",
    name: "media-report",
    component: () => import(/* webpackChunkName: "media" */ "../views/MediaReport.vue"),
    meta: {
      layout: "blank",
      middleware: [AuthMiddleware],
      title: "Relatório de Mídias",
      guards: ["acl"],
      slug: "communications.pdf.download",
    },
  },

  {
    path: "/media/create",
    name: "media-create",
    component: () => import(/* webpackChunkName: "media" */ "../views/MediaForm.vue"),
    props: true,
    meta: {
      layout: "blank",
      middleware: [AuthMiddleware],
      title: "Nova Mídia",
      guards: ["acl"],
      slug: "media.create",
    },
  },

  {
    path: "/media/duplicate/:id",
    props: true,
    name: "media-duplicate",
    component: () => import(/* webpackChunkName: "media" */ "../views/MediaForm.vue"),
    meta: {
      layout: "blank",
      middleware: [AuthMiddleware],
      title: "Duplicar Mídia",
      guards: ["acl"],
      slug: "media.create",
    },
  },

  {
    path: "/media/:id",
    props: true,
    name: "media-edit",
    component: () => import(/* webpackChunkName: "media" */ "../views/MediaForm.vue"),
    meta: {
      layout: "blank",
      middleware: [AuthMiddleware],
      title: "Editar Mídia",
    },
  },

  {
    path: "/media/show/:id",
    props: true,
    name: "media-show",
    component: () => import(/* webpackChunkName: "media" */ "../views/MediaShow.vue"),
    meta: {
      middleware: [AuthMiddleware],
      title: "Visualizar Mídia",
    },
  },

  {
    path: "/media/show/analytic/:id",
    props: true,
    name: "media-show-analytic",
    component: () => import(/* webpackChunkName: "media" */ "../views/MediaShowAnalytic.vue"),
    meta: {
      middleware: [AuthMiddleware],
      title: "Visualizar Mídia",
    },
  },

  {
    path: "/media/shared/:hash",
    props: true,
    name: "media-shared",
    component: () => import(/* webpackChunkName: "media" */ "../views/MediaShared.vue"),
    meta: {
      title: "Mídia compartilhada",
      layout: "shared",
    },
  },
];
