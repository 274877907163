import Http from "@/plugins/http";
import { convertToFormData } from "@/helpers/object.js";

export const getFolders = (accountId, page = 1) => {
  return Http.get(`storage/${accountId}/folders`, {
    params: {
      page,
    },
  });
};

export const getFolder = (accountId, id, params = {}) => {
  return Http.get(`storage/${accountId}/folders/${id}`, { params });
};

export const createFolder = (accountId, data, args = {}) => {
  if (!(data instanceof FormData)) data = convertToFormData(data);

  return Http.post(`storage/${accountId}/folders`, data, {
    args,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const updateFolder = (accountId, id, params) => {
  return Http.put(`storage/${accountId}/folders/${id}`, params);
};

export const deleteFolder = (accountId, id) => {
  return Http.delete(`storage/${accountId}/folders/${id}`);
};

export const moveFilesToFolder = (accountId, id, filesId) => {
  return Http.post(`storage/${accountId}/folders/${id}/move-files`, {
    filesId,
  });
};

export const downloadFolders = (accountId, foldersId) => {
  return Http.get(`storage/${accountId}/folders/download`, {
    params: { foldersId },
    responseType: "blob",
  });
};

export const findByHash = (hash, params) => {
  return Http.get(`storage/folders/share/${hash}`, { params });
};

export const enableShare = (accountId, id, params) => {
  return Http.put(`storage/${accountId}/folders/share/${id}/enable`, params);
};

export const disableShare = (accountId, id, params) => {
  return Http.put(`storage/${accountId}/folders/share/${id}/disable`, params);
};
