<template>
  <component :is="layout" />
</template>

<script>
import LayoutConfig from "@/config/layout";

export default {
  name: "App",
  computed: {
    layout() {
      const { main, layouts } = LayoutConfig;
      const { layout } = this.$route.meta;

      if (_.has(layouts, layout)) {
        return layouts[layout];
      }

      return layouts[main];
    },
  },
};
</script>
