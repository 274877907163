export default class Drop {
  constructor(element, data) {
    this.element = element;
    this.id = data.id;
    this.action = data.action;
  }

  start() {
    this.configElement();
    this.addDropListener();
    this.addDragLeaveListener();
    this.addDragEnterListener();
  }

  addDropListener() {
    this.element.addEventListener("drop", (event) => {
      this.removeDropOverStyle(event);
      if (this.isStrict && !this.canDrop(event)) return;
      const value = this.getData(event);
      this.resolveAction(value);
    });
  }

  addDragLeaveListener() {
    this.element.addEventListener("dragleave", (event) => {
      if (!this.element.contains(event.relatedTarget)) this.removeDropOverStyle(event);
    });
  }

  addDragEnterListener() {
    this.element.addEventListener("dragenter", (event) => {
      if (!this.element.contains(event.relatedTarget)) this.applyDropOverStyle(event);
    });
  }

  canDrop(event) {
    const id = event.dataTransfer.getData(this.id);
    if (id !== this.id) return false;
    return true;
  }

  getData(event) {
    let value = event.dataTransfer.getData("value");
    try {
      value = JSON.parse(value);
    } catch (e) {
      throw e;
    }
    return value;
  }

  resolveAction(value) {
    const draggingElement = document.querySelector("[dragging=true]");
    let resolve = null;
    let reject = null;
    const promise = new Promise((res, rej) => {
      resolve = res;
      reject = rej;
    });

    const event = new Event("drop:success", promise);
    event.promise = promise;
    draggingElement.dispatchEvent(event);
    if (typeof this.action === "function") return this.action(value, { resolve, reject });
  }

  configElement() {
    this.element.addEventListener("dragover", (event) => {
      event.preventDefault();
      event.dataTransfer.dropEffect = "move";
    });
  }

  applyDropOverStyle(event) {
    if (this.canApplyStyle(event)) this.element.classList.add("dropOver");
  }

  removeDropOverStyle(event) {
    if (this.canApplyStyle(event)) this.element.classList.remove("dropOver");
  }

  canApplyStyle(event) {
    if (this.isStrict) {
      const canDropArea = event.dataTransfer.types.some((n) => n === this.id);
      if (canDropArea) return true;
      return false;
    }
    return true;
  }

  get isStrict() {
    return this.id ? true : false;
  }
}
