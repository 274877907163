export default class Drag {
  constructor(element, data) {
    this.element = element;
    this.id = data.id;
    this.action = data.action;
    this.value = data.value;
  }

  start() {
    this.configElement();
    this.addDragStartListener();
    this.addDragEndListener();
  }

  addDragStartListener() {
    this.element.addEventListener("dragstart", (event) => {
      if (this.isStrict) event.dataTransfer.setData(this.id, this.id);
      this.element.style.opacity = 0.5;
      event.target.setAttribute("dragging", true);
      this.setValueInDataTransfer(event.dataTransfer);
    });
  }

  setValueInDataTransfer(dataTransfer) {
    dataTransfer.setData("value", this.formattedValue);
  }

  addDragEndListener() {
    this.element.addEventListener("dragend", (event) => {
      if (this.hasChildren) this.enableChildrenEvents();
      this.element.style.opacity = 1;
      event.dataTransfer.clearData(this.id);
      event.target.removeAttribute("dragging");
    });
  }

  configElement() {
    this.element.setAttribute("draggable", true);
    this.element.addEventListener("drag", () => {
      this.disableChildrenEvents();
    });
  }

  disableChildrenEvents() {
    this.element.children[0].style.pointerEvents = "none";
  }

  enableChildrenEvents() {
    this.element.children[0].style.pointerEvents = "auto";
  }

  setValue(value) {
    this.value = value;
  }

  get isStrict() {
    return this.id ? true : false;
  }

  get hasChildren() {
    return this.element.children.length ? true : false;
  }

  get formattedValue() {
    let { value } = this;
    if (typeof value === "object") value = JSON.stringify(value);
    return value;
  }
}
