import AuthMiddleware from "@/middleware/auth";

export default [
  {
    path: "/tag",
    name: "config.tag",
    component: () => import(/* webpackChunkName: "feed" */ "../views/Tag.vue"),
    meta: {
      middleware: [AuthMiddleware],
      title: "Empresas",
      layout: "config",
      guards: ["acl", "feature"],
      slug: "config.view",
      featureSlug: "tags",
    },
  },
];
