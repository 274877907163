import "./bootstrap";
import Vue from "vue";
import VeeValidate, { Validator } from "vee-validate";
import ptBr from "vee-validate/dist/locale/pt_BR";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import authorization from "./helpers/authorization";
import vuetify from "./plugins/vuetify";
import acl from "./plugins/acl";
import features from "./plugins/accountFeatures";
import config from "./plugins/config";
import dragAndDrop from "./plugins/dragAndDrop/index.js";

Vue.use(dragAndDrop);
Vue.use(acl);
Vue.use(features);
Vue.use(config);
Vue.use(VeeValidate, { inject: false });
Vue.use(authorization);

Validator.extend(
  "required_object",
  (value, [otherValue]) => {
    return value[otherValue] ? true : false;
  },
  {
    hasTarget: true,
    immediate: false,
  },
);

Validator.localize("pt_BR", ptBr);
Vue.config.productionTip = process.env.NODE_ENV !== "production";
Vue.config.performance = process.env.NODE_ENV !== "production";

Vue.filter("formatDateBR", (date) => {
  return date.split("-").reverse().join("/");
});

new Vue({ router, store, vuetify, render: (h) => h(App) }).$mount("#app");
