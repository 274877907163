/* eslint no-param-reassign: ["error", { "props": false }] */
export default {
  setNavigationDrawer(state, navigationDrawer) {
    localStorage.setItem("navigationDrawer", JSON.stringify(navigationDrawer));
    state.navigationDrawer = navigationDrawer;
  },
  clearNavigationDrawer(state) {
    localStorage.clear("navigationDrawer");
    state.navigationDrawer = null;
  },
};
