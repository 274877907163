/* eslint no-param-reassign: ["error", { "props": false }] */
import axios from "axios";
import route from "@/router";
import store from "@/store.js";
import { preventErrorMessage } from "@/plugins/swal.js";

const client = axios.create({
  baseURL: process.env.VUE_APP_API,
  headers: {
    Accept: "application/json",
  },
});

const logout = (error = null) => {
  const routeName = route.history.current.name;
  if (
    ["login", "password-reset", "shared.prototype", "shared.mapping", "shared.sub-sections"].some(
      (name) => name === routeName,
    )
  )
    return Promise.reject(error);

  preventErrorMessage();

  store.commit("auth/clearSession", () => {
    preventErrorMessage(false);
  });

  return route.push({ name: "login" });
};

/* Before each request */
client.interceptors.request.use(
  async (config) => {
    // Check if access token exists
    // await checkAndUpdateRefreshToken();
    const accessToken = localStorage.getItem("accessToken");

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => error,
);

/* On each response */
client.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response.status === 401) return logout(error);

    return Promise.reject(error);
  },
);

export default client;
