import store from "@/store";

class Acl {
  constructor() {
    this.Vue = null;
    this.options = {
      vmProperty: "$acl",
    };
  }

  install(Vue) {
    this.Vue = Vue;
    this.registerVmProperty();
  }

  registerVmProperty() {
    const { vmProperty } = this.options;
    this.Vue.prototype[vmProperty] = this;
  }

  can(slugsParam) {
    if (!store.state.auth || !store.state.auth.user || !store.state.auth.user.role.scopes)
      return false;
    const user = { ...store.state.auth.user };
    const userScopes = [...user.role.scopes, ...user.scopes];

    if (slugsParam.includes("&")) {
      const slugs = slugsParam.split("&");

      return slugs.every((slug) => {
        return userScopes.some((scope) => scope.slug === slug);
      });
    }

    const slugs = slugsParam.split("|");

    return slugs.some((slug) => {
      return userScopes.some((scope) => scope.slug === slug);
    });
  }
}

export default new Acl();
