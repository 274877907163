// main.js
import Vue from "vue";
import SwalAlert2 from "vue-sweetalert2";
import Swal from "sweetalert2";

const options = {
  backdrop: "#00000082",
};

Vue.use(SwalAlert2, options);
let preventError = false;

export function preventErrorMessage(status = true) {
  preventError = status;
}
// Swal
Vue.prototype.$alert = {
  delete: (callback, options = {}) => {
    Swal.fire({
      title: "Tem certeza que deseja excluir?",
      text: "Uma vez deletado não poderá recuperar",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0d6efd",
      cancelButtonColor: "#d9003c",
      confirmButtonText: "Sim, concluir!",
      cancelButtonText: "Cancelar",
      ...options,
    }).then((result) => {
      if (result.value) {
        callback();
      }
    });
  },
  confirm(callback, options = {}) {
    const defaultOptions = {
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Sim, Continuar!",
    };
    return Swal.fire({
      ...defaultOptions,
      ...options,
    }).then((result) => {
      if (result.value) return callback();
      return false;
    });
  },
  error(message, options = {}) {
    if (preventError) return;

    const defaultOptions = {
      icon: "error",
      title: "Erro",
      text: message,
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Ok",
    };
    return Swal.fire({
      ...defaultOptions,
      ...options,
    });
  },
};

//  Toast
const Toast = Swal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  showCloseButton: true,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

Vue.prototype.$toast = {
  success: (title) => {
    Toast.fire({
      icon: "success",
      title,
      background: "#deffe3",
    });
  },
  error: (title) => {
    if (preventError) return;

    Toast.fire({
      icon: "error",
      title,
      background: "#fce3e3",
      customClass: {
        closeButton: "closeButtonCollor",
      },
    });
  },
};

export default {
  preventErrorMessage,
};
