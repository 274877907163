import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import ThemeConfig from "@/config/themes";

Vue.use(Vuetify);

const user = JSON.parse(localStorage.getItem("user"));
const config = ThemeConfig;

if (user && user.account && user.account.options) {
  const options = JSON.parse(user.account.options);

  if (options.vuetify) {
    config.theme = options.vuetify.theme;
  }
}

export default new Vuetify(config);
