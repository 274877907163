import "core-js/stable";
import "regenerator-runtime/runtime";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import "@/assets/sass/app.scss";
import "./plugins/swal";
import "./plugins/quill";
import "./plugins/toastr";
import "./plugins/read-more";
import "./plugins/moment";
import "./plugins/highcharts";
import "./plugins/apexchats";
import "./plugins/json-editor";
import Vue from "vue";
import modules from "@/config/modules";
import VueModules from "@/plugins/modules";
import router from "./router";
import store from "./store";

window._ = require("lodash");
window.axios = require("axios");
window.Swall = require("sweetalert2");
Vue.use(VueModules, { modules, router, store });
