import AuthMiddleware from "@/middleware/auth";

export default [
  {
    path: "/products",
    name: "products",
    component: () => import("@/modules/products/views/Products.vue"),
    meta: {
      middleware: [AuthMiddleware],
      layout: "config",
      title: "Produtos",
      guards: ["acl", "feature"],
      slug: "product.view",
      featureSlug: "product",
    },
  },
  {
    path: "/products/pj",
    name: "products.pj",
    component: () => import("@/modules/products/views/Products.vue"),
    props: {
      filterType: true,
    },
    meta: {
      middleware: [AuthMiddleware],
      layout: "config",
      title: "Produtos PJ",
      guards: ["acl"],
      slug: "prototype.pj.view",
      featureSlug: "product",
    },
  },
  {
    path: "/products/pf",
    name: "products.pf",
    component: () => import("@/modules/products/views/Products.vue"),
    props: {
      filterType: true,
    },
    meta: {
      middleware: [AuthMiddleware],
      layout: "config",
      title: "Produtos PF",
      guards: ["acl"],
      slug: "prototype.pf.view",
      featureSlug: "product",
    },
  },
  {
    path: "/products/groups",
    name: "products.groups",
    component: () => import("@/modules/products/views/ProductGroups.vue"),
    meta: {
      middleware: [AuthMiddleware],
      layout: "config",
      title: "Produtos",
      guards: ["acl", "feature"],
      slug: "product.view",
      featureSlug: "product",
    },
  },
  {
    path: "/products/:id/prototypes",
    name: "prototypes.details",
    component: () => import("@/modules/products/views/PrototypeDetails.vue"),
    props: true,
    meta: {
      middleware: [AuthMiddleware],
      layout: "config",
      title: "Protótipos",
      guards: ["acl", "feature"],
      slug: "product.prototype.view",
      featureSlug: "product",
    },
  },
  {
    path: "/products/:id",
    name: "products.details",
    component: () => import("@/modules/products/views/ProductDetails.vue"),
    props: true,
    meta: {
      middleware: [AuthMiddleware],
      layout: "config",
      title: "Produtos",
      guards: ["acl", "feature"],
      slug: "product.view",
      featureSlug: "product",
    },
  },
  {
    path: "/products/:id/comparing",
    name: "products.comparing",
    component: () => import("@/modules/products/views/VersionComparison.vue"),
    props: true,
    meta: {
      middleware: [AuthMiddleware],
      layout: "config",
      title: "Comparativo de versões",
      guards: ["acl", "feature"],
      slug: "product.view",
      featureSlug: "product",
    },
  },
  {
    path: "/products/:productId/mappings",
    name: "mappings.view",
    component: () => import("@/modules/products/views/ScreenshotMappings.vue"),
    props: true,
    meta: {
      middleware: [AuthMiddleware],
      layout: "config",
      title: "Produtos",
      guards: ["acl", "feature"],
      slug: "product.mapping.view",
      featureSlug: "product",
    },
  },
  {
    path: "/products/:productId/mappings/:id",
    name: "mappings.show",
    component: () => import("@/modules/products/views/MappingDetails.vue"),
    props: true,
    meta: {
      middleware: [AuthMiddleware],
      layout: "config",
      title: "Produtos",
      guards: ["acl", "feature"],
      slug: "product.mapping.view",
      featureSlug: "product",
    },
  },
  {
    path: "/products/:productId/mappings/:mappingId/section/:sectionId/subsections",
    name: "subsection.view",
    component: () => import("@/modules/products/views/SubSections.vue"),
    props: true,
    meta: {
      middleware: [AuthMiddleware],
      layout: "config",
      title: "Produtos",
      guards: ["acl", "feature"],
      slug: "product.mapping.subsection.view",
      featureSlug: "product",
    },
  },

  {
    path: "/products/:id/prototype",
    name: "product.prototype",
    component: () => import("@/modules/products/views/ProductPrototype.vue"),
    props: true,
    meta: {
      middleware: [AuthMiddleware],
      layout: "config",
      title: "Produtos",
      guards: ["acl", "feature"],
      slug: "product.prototype.view",
      featureSlug: "product",
    },
  },
  {
    path: "/products/:id/prototype/:prototypeId",
    name: "embed.figma",
    component: () => import("@/modules/products/views/PrototypeInfo.vue"),
    props: true,
    meta: {
      middleware: [AuthMiddleware],
      layout: "config",
      title: "Produtos",
      guards: ["acl", "feature"],
      slug: "product.prototype.view",
      featureSlug: "product",
    },
  },
  {
    path: "/prototype/:hash",
    props: true,
    name: "shared.prototype",
    component: () => import("@/modules/products/views/SharedPrototypeInfo.vue"),
    meta: {
      layout: "shared",
      title: "Protótipo compartilhado",
    },
  },
  {
    path: "/mapping/:hash",
    props: true,
    name: "shared.mapping",
    component: () => import("@/modules/products/views/SharedMappingInfo.vue"),
    meta: {
      layout: "shared",
      title: "Mapeamento compartilhado",
    },
  },
  {
    path: "/mapping/:hash/sub-sections/:parentId",
    props: true,
    name: "shared.sub-sections",
    component: () => import("@/modules/products/views/SharedSubSections.vue"),
    meta: {
      layout: "shared",
      title: "Subseção de mapeamento compartilhado",
    },
  },
];
