export default {
  setMediaCollections(state, mediaCollections) {
    localStorage.setItem("mediaCollections", JSON.stringify(mediaCollections));
    state.mediaCollections = mediaCollections;
  },
  addMediaCollection(state, mediaCollection) {
    if (mediaCollection) {
      state.mediaCollections.push(mediaCollection);
    }
  },
  updateMediaCollection(state, mediaCollection) {
    if (mediaCollection && state.mediaCollections.length) {
      state.mediaCollections.forEach((item, index) => {
        if (item._id === mediaCollection._id) {
          state.mediaCollections[index] = mediaCollection;
        }
      });
    }
  },
  removeMediaCollection(state, mediaCollection) {
    if (mediaCollection && state.mediaCollections.length) {
      state.mediaCollections.forEach((item, index) => {
        if (item._id === mediaCollection._id) {
          state.mediaCollections.splice(index, 1);
          localStorage.setItem("mediaCollections", JSON.stringify(state.mediaCollections));
        }
      });
    }
  },

  setListMedias(state, listMedias) {
    state.listMedias += listMedias;
  },
  setMediasLikedByUser(state, mediasLikedByUser) {
    state.mediasLikedByUser = mediasLikedByUser;
  },
  incrementMediaLiked(state, mediaId) {
    state.mediasLikedByUser.push(mediaId);
  },
  removeLikedMedia(state, mediaId) {
    const likeIndexToRemove = state.mediasLikedByUser.indexOf(mediaId);
    state.mediasLikedByUser.splice(likeIndexToRemove, 1);
  },

  clearMediaCollections(state) {
    localStorage.removeItem("mediaCollections");
    state.mediaCollections = [];
  },
};
