export default {
  /**
   * Default layout fallback
   */
  main: "app",
  /**
   * Available layouts
   */
  layouts: {
    app: () => import(/* webpackChunkName: "app-layout" */ "@/layouts/app/AppLayout.vue"),
    config: () => import(/* webpackChunkName: "app-layout" */ "@/layouts/config/ConfigLayout.vue"),
    auth: () => import(/* webpackChunkName: "app-layout" */ "@/layouts/auth/AuthLayout.vue"),
    shared: () => import(/* webpackChunkName: "app-layout" */ "@/layouts/shared/SharedLayout.vue"),
    blank: () => import(/* webpackChunkName: "app-layout" */ "@/layouts/blank/BlankLayout.vue"),
  },
};
