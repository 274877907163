/**
 * Change browser page titles
 *
 * @param title
 * @return void
 */
export function setPageTitle(title) {
  if (!_.isEmpty(title)) {
    window.document.title = `${title} | ${process.env.VUE_APP_NAME}`;
    return;
  }

  window.document.title = process.env.VUE_APP_NAME;
}

export function changePreventReload(toggler) {
  if (!toggler) return (window.onbeforeunload = null);
  window.onbeforeunload = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };
}

export const pushQueryParams = (params, value = null) => {
  const url = new URL(window.location.href);

  if (typeof params === "string") {
    if (value === null) url.searchParams.delete(params);
    else url.searchParams.set(params, value);
  } else {
    Object.keys(params).forEach((key) => {
      if (params[key] === null || params[key] === "" || params[key] === []) {
        url.searchParams.delete(key);
        return;
      }
      const value = typeof params[key] === "object" ? JSON.stringify(params[key]) : params[key];
      url.searchParams.set(key, value);
    });
  }

  history.replaceState(history.state, null, url);
};

// suport only array and string.
export const toggleQueryString = (params = {}) => {
  const url = new URL(window.location.href);

  Object.keys(params).forEach((key) => {
    const value = params[key];

    if (!value) {
      url.searchParams.delete(key);
      return;
    }

    if (typeof value === "string") {
      url.searchParams.set(key, value);
    }

    if (Array.isArray(value)) {
      url.searchParams.delete(key);

      value.forEach((item) => {
        url.searchParams.append(key, item);
      });
    }
  });

  history.replaceState(history.state, null, url);
};

export default {};
