import AuthMiddleware from "@/middleware/auth";

export default [
  {
    path: "/clippings",
    name: "clippings",
    component: () => import(/* webpackChunkName: "clipping" */ "../views/ClippingList.vue"),
    meta: {
      middleware: [AuthMiddleware],
      title: "Clippings",
      guards: ["acl"],
      slug: "clipping.view",
    },
  },
  {
    path: "/clippings/create/:id?",
    name: "clipping.create",
    props: true,
    component: () =>
      import(/* webpackChunkName: "clipping" */ "../components/steps/ClippingStepper.vue"),
    meta: {
      middleware: [AuthMiddleware],
      title: "Novo Clipping",
      layout: "blank",
      guards: ["acl"],
      slug: "clipping.create",
    },
  },
  {
    path: "/clippings/draft",
    name: "clipping.draft",
    component: () => import(/* webpackChunkName: "clipping" */ "../views/ClippingDraft.vue"),
    meta: {
      middleware: [AuthMiddleware],
      title: "Rascunho Clipping",
      guards: ["acl"],
      slug: "clipping.draft.view",
    },
  },
  {
    path: "/clippings/audience",
    name: "clipping.audience",
    component: () => import(/* webpackChunkName: "clipping" */ "../views/Audience.vue"),
    meta: {
      middleware: [AuthMiddleware],
      title: "Audience",
      guards: ["acl"],
      slug: "clipping.view",
    },
  },
  {
    path: "/clippings/templates",
    name: "clipping.templates.view",
    component: () => import(/* webpackChunkName: "clipping" */ "../views/ClippingTemplates.vue"),
    meta: {
      middleware: [AuthMiddleware],
      title: "Templates do clipping",
      layout: "config",
      guards: ["acl", "feature"],
      slug: "clipping.templates.view.all",
      featureSlug: "clipping",
    },
  },
  {
    path: "/clippings/:id",
    name: "clipping.dashboard",
    component: () => import(/* webpackChunkName: "clipping" */ "../views/ClippingDashboard.vue"),
    meta: {
      middleware: [AuthMiddleware],
      title: "Clipping Dashboard",
      guards: ["acl"],
      slug: "clipping.dashboard.default",
    },
  },
];
