/* eslint no-param-reassign: ["error", { "props": false }] */
export default {
  setUser(state, user) {
    localStorage.setItem("user", JSON.stringify(user));
    state.user = user;
  },
  setUserAreas(state, areas) {
    state.user.areas = areas;
    localStorage.setItem("user", JSON.stringify(state.user));
  },
  setAvatar(state, avatar) {
    state.user.avatar = avatar;
    localStorage.setItem("user", JSON.stringify(state.user));
  },
  setAccessToken(state, accessToken) {
    localStorage.setItem("accessToken", accessToken);
    state.accessToken = accessToken;
  },
  setRefreshToken(state, refreshToken) {
    localStorage.setItem("refreshToken", refreshToken);
    state.refreshToken = refreshToken;
  },
  setSessionToken(state, sessionToken) {
    localStorage.setItem("sessionToken", sessionToken);
    state.sessionToken = sessionToken;
  },
  setUserAccount(state, accountID) {
    state.user.accountID = accountID;
    localStorage.setItem("user", JSON.stringify(state.user));
  },
  clearUser(state) {
    localStorage.removeItem("user");
    state.user = null;
  },
  clearAccessToken(state) {
    localStorage.removeItem("accessToken");
    state.accessToken = null;
  },
  clearRefreshToken(state) {
    localStorage.removeItem("refreshToken");
    state.refreshToken = null;
  },
  clearSession() {
    localStorage.clear();
  },
  setDrawer(state, drawer) {
    state.drawer = drawer;
  },
};
