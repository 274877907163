/* eslint-disable */
export default {
  feed: require('@/modules/feed').default,
  auth: require('@/modules/auth').default,
  user: require('@/modules/user').default,
  media: require('@/modules/media').default,
  search: require('@/modules/search').default,
  config: require('@/modules/config').default,
  player: require('@/modules/player').default,
  source: require('@/modules/source').default,
  tag: require('@/modules/tag').default,
  newsMonitoring: require('@/modules/newsMonitoring').default,
  clipping: require('@/modules/clipping').default,
  contacts: require('@/modules/contacts').default,
  groups: require('@/modules/groups').default,
  acl: require('@/modules/acl').default,
  home: require('@/modules/home').default,
  dashboard: require('@/modules/dashboard').default,
  rss: require('@/modules/rss').default,
  mappings: require('@/modules/mapping').default,
  products: require('@/modules/products').default,
  storage: require('@/modules/storage').default,
  library: require('@/modules/library').default,
  btl: require('@/modules/btl').default
};
