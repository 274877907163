import AuthMiddleware from "@/middleware/auth";

export default [
  {
    path: "/",
    name: "feed",
    component: () => import(/* webpackChunkName: "feed" */ "../views/Feed.vue"),
    meta: {
      middleware: [AuthMiddleware],
      title: "Meu Feed",
    },
  },
  {
    path: "/feed/:mediaType",
    name: "feed.content",
    props: true,
    component: () => import(/* webpackChunkName: "feed" */ "../views/FeedContent.vue"),
    meta: {
      middleware: [AuthMiddleware],
      title: "Conteúdo",
    },
  },
  {
    path: "/feed/all/search",
    name: "feed.all.search",
    props: true,
    component: () =>
      import(/* webpackChunkName: "feed.search" */ "@/modules/search/views/FeedSearch.vue"),
    meta: {
      middleware: [AuthMiddleware],
      title: "Resultado de Busca",
    },
  },
  {
    path: "/feed/content/:mediaType/search",
    name: "feed.search",
    props: true,
    component: () =>
      import(/* webpackChunkName: "feed.search" */ "@/modules/search/views/Search.vue"),
    meta: {
      middleware: [AuthMiddleware],
      title: "Resultado de Busca",
    },
  },
  {
    path: "/media-collections",
    name: "feed.mediaCollections",
    component: () => import(/* webpackChunkName: "feed" */ "../views/FeedMediaCollections.vue"),
    meta: {
      middleware: [AuthMiddleware],
      title: "Coleção de mídias",
    },
  },
  {
    path: "/feed/media-likes/all",
    name: "feed.mediaLikes",
    component: () => import(/* webpackChunkName: "feed" */ "../views/FeedMediaLikes.vue"),
    meta: {
      middleware: [AuthMiddleware],
      title: "Coleção de Likes",
    },
  },
];
