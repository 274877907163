import multiguard from "vue-router-multiguard";
import guardConfig from "@/config/guards";

class ModulesPlugin {
  constructor() {
    this.Vue = null;
    this.options = {
      modules: {},
      vmProperty: "$modules",
    };
  }

  install(Vue, options) {
    this.Vue = Vue;
    this.options = Object.assign(this.options, options);

    this.registerVmProperty();
    this.registerModules();
  }

  registerVmProperty() {
    const { vmProperty, modules } = this.options;
    this.Vue.prototype[vmProperty] = modules;
  }

  registerModules() {
    const { modules } = this.options;

    if (Object.keys(modules).length) {
      Object.keys(modules).forEach((moduleName) => {
        const module = modules[moduleName];
        const hasPromisse = typeof module.then === "function";

        // Loaded module
        if (!hasPromisse) {
          this.registerModuleRoutes(module.routes);
          this.registerModuleStore(moduleName, module.store);

          return;
        }

        // Load lazy modules
        module.then((moduleLoaded) => {
          const { routes, store } = moduleLoaded.default;

          this.registerModuleRoutes(routes);
          this.registerModuleStore(moduleName, store);
        });
      });
    }
  }

  registerModuleRoutes = (moduleRoutes) => {
    if (moduleRoutes) {
      _.forIn(moduleRoutes, (route, key) => {
        if (_.has(route, "meta.guards")) {
          // Filter route guard from @/config/guards
          const routeGuards = _.values(_.pick(guardConfig.guards, route.meta.guards));
          // Apply guards
          _.set(moduleRoutes[key], "beforeEnter", multiguard(routeGuards));
        }
      });
      this.options.router.addRoutes(moduleRoutes);
    }
  };

  registerModuleStore = (moduleName, moduleStore) => {
    if (moduleStore) {
      this.options.store.registerModule(moduleName, moduleStore);
    }
  };
}
export default new ModulesPlugin();
