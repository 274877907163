import Http from "@/plugins/http";

export default {
  async getUser(context, callback) {
    await Http.get("user/auth", {
      params: {
        include: [
          "accounts",
          "scopes",
          "account",
          "account.metas",
          "account.features",
          "areas",
          "role",
        ],
      },
    })
      .then((response) => {
        const { user } = response.data;

        context.commit("setUser", user);
        if (callback) return callback(true);
      })
      .catch(() => {
        if (callback) return callback(false);
      });
  },

  async syncAreasInterest(context, userUpdate) {
    const response = await Http.post("/user/areas", { areas: userUpdate.areasInterest });
    if (response && response.data && response.data.areas) {
      context.commit("setUserAreas", response.data.areas);
    }
  },

  async logout(context, callback) {
    await Http.post(`/auth/revoke-token`, {
      refreshToken: context.state.refreshToken,
      email: context.state.user.email,
    });
    await context.commit("clearSession");

    if (process.env.VUE_APP_REDIRECT_LOGOUT) {
      window.location.href = process.env.VUE_APP_REDIRECT_LOGOUT;
      return;
    }
    if (callback) {
      await callback();
    }
  },
};
