import AuthMiddleware from "@/middleware/auth";

export default [
  {
    path: "/user/account",
    name: "user.account",
    component: () => import("../views/UserAccount.vue"),
    meta: {
      middleware: [AuthMiddleware],
      title: "Minha Conta",
    },
  },
  {
    path: "/user/manage-sessions",
    name: "manage.sessions",
    component: () => import("../views/ManageSessions.vue"),
    props: true,
    meta: {
      middleware: [AuthMiddleware],
      title: "Gerenciar sessões",
    },
  },
  {
    path: "/user/firstLogin",
    name: "user.firstLogin",
    component: () => import("../views/ChangePassword.vue"),
    meta: {
      title: "Trocar senha",
      layout: "auth",
    },
  },
  {
    path: "/user/about",
    name: "user.about",
    component: () => import("../views/UserAbout.vue"),
    meta: {
      middleware: [AuthMiddleware],
      title: "Sobre",
    },
  },
  {
    path: "/terms",
    name: "terms",
    component: () => import("../views/UserAbout.vue"),
    meta: {
      title: "Sobre",
      layout: "shared",
    },
  },
];
