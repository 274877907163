import store from "@/store";

class AccountFeatures {
  constructor() {
    this.Vue = null;
    this.options = {
      vmProperty: "$feature",
    };
  }

  install(Vue) {
    this.Vue = Vue;
    this.registerVmProperty();
  }

  registerVmProperty() {
    const { vmProperty } = this.options;
    this.Vue.prototype[vmProperty] = this;
  }

  enable(slugsParam) {
    if (!store.state.auth || !store.state.auth.user || !store.state.auth.user.account.features)
      return false;

    const account = { ...store.state.auth.user.account };

    if (slugsParam.includes("&")) {
      const slugs = slugsParam.split("&");

      return slugs.every((slug) => {
        return account.features.some((feature) => feature.slug === slug);
      });
    }

    const slugs = slugsParam.split("|");

    return slugs.some((slug) => {
      return account.features.some((scope) => scope.slug === slug);
    });
  }
}

export default new AccountFeatures();
