export default {
  queue: [],
  consuming: 0,
  uploading: false,
  uploaded: [],
  //  download
  downloading: false,
  downloadQueue: [],
  downloadConsuming: 0,
};
