export default {
  addInQueue(state, data) {
    state.uploading = true;
    data.internalId = state.queue.length;
    const { type, fileList, folderId } = data;
    if (type === "folder") {
      const folderName = fileList[0].webkitRelativePath.split("/")[0];
      state.queue.push({
        name: folderName,
        ...data,
        type: "folder",
      });
      return;
    }

    state.queue.push({
      type: "chunk-files",
      folderId,
      files: fileList,
    });
  },
  setUploading(state, value) {
    state.uploading = value;
  },
  consuming(state) {
    state.consuming++;
  },
  clearQueue(state) {
    state.consuming = 0;
    state.queue = [];
  },
  setUploaded(state, values) {
    state.uploaded.push(values);
  },
  clearUploaded(state) {
    state.uploaded = [];
  },
  setDownloading(state, value) {
    state.downloading = value;
  },
  addInDownloadQueue(state, { item, type }) {
    state.downloading = true;
    if (type === "folder") {
      state.downloadQueue.push({
        type: "folder",
        folders: [...item],
      });
      return;
    }

    state.downloadQueue.push({
      type: "file",
      files: [...item],
    });
  },
  clearDownloadQueue(state) {
    state.downloadQueue = [];
    state.downloadConsuming = 0;
  },
  consumingDownload(state) {
    state.downloadConsuming++;
  },
  removeQueueItem(state, internalId) {
    const index = state.queue.findIndex((item) => item.internalId === internalId);
    if (index !== -1) {
      state.queue.splice(index, 1);
    }
    if (state.queue.length === 0) {
      state.uploading = false;
    }
  },
};
